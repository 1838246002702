import { defineStore } from "pinia";
import { FormData } from "@/types";
type GeneralStore = {
  loader: boolean;
  screen: "landingPage" | "form" | "success" | "error" | "loading" | "passwordPage";
  formData: FormData | null;
};

export const generalStore = defineStore("generalStore", {
  state: (): GeneralStore => {
    return {
      loader: false,
      screen: "loading",
      formData: null,
    };
  },
  getters: {},
  actions: {
    setLoader(value: boolean) {
      this.loader = value;
    },
  },
});
