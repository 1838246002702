import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "FallBack",
    component: () => import(/* webpackChunkName: "leadifyIt" */ "@/views/FallBack.vue"),
  },
  {
    path: "/:token",
    name: "FormView",
    component: () => import(/* webpackChunkName: "leadifyIt" */ "@/views/FormView.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "FallBack",
    component: () => import(/* webpackChunkName: "leadifyIt" */ "@/views/FallBack.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
