import publicPath from "./public-path";

import axios from "axios";
axios.defaults.baseURL = publicPath;

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Format from "./mixins/Format";
import Directives from "./directives";
import { createPinia } from "pinia";

declare module "vue" {
  interface ComponentCustomProperties {
    window: Window;
    $format: Format;
  }
}
if (!document.getElementById("leadifyit")) {
  const body = document.getElementsByTagName("body")[0];
  const insertPoint = document.getElementById("leadify-insertion-point");
  if (body) {
    const container = document.createElement("div");
    container.id = "leadifyit";

    if (insertPoint) {
      insertPoint.insertBefore(container, document.getElementById("leadify-end-point"));
    } else {
      body.appendChild(container);
    }
  }
}

const app = createApp(App);
const pinia = createPinia();
axios.defaults.withCredentials = false;

app.config.globalProperties.window = window;
app.config.globalProperties.$format = new Format();
app.use(router);
app.use(Directives);
app.use(pinia);
app.mount("#leadifyit");
