import { App, DirectiveBinding } from "vue";

interface ExtendedHTMLElement extends HTMLElement {
  stickyScrollHandler?: () => void;
}

export default function (Vue: App) {
  function bind(el: ExtendedHTMLElement, binding: DirectiveBinding<string>) {
    unbind(el);

    const targetId = binding.value;
    const targetElement = document.getElementById(targetId);
    if (!targetElement) {
      console.warn(`Element with ID "${targetId}" not found.`);
      return;
    }
    const topSpace = parseInt(binding.arg ?? "0");

    el.stickyScrollHandler = function () {
      const rect = targetElement.getBoundingClientRect();
      if (rect.top <= topSpace) {
        el.classList.add("sticky");
      } else {
        el.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", el.stickyScrollHandler);
    el.stickyScrollHandler();
  }

  function unbind(el: ExtendedHTMLElement) {
    if (el.stickyScrollHandler) {
      window.removeEventListener("scroll", el.stickyScrollHandler);
    }
    delete el.stickyScrollHandler;
  }
  Vue.directive("sticky", {
    mounted: bind,
    updated: function (el: ExtendedHTMLElement, binding: DirectiveBinding<string>) {
      if (binding.value === binding.oldValue) return;
      bind(el, binding);
    },
    beforeUnmount: unbind,
  });
}
